// @ts-nocheck
import * as Ably from 'ably';
import { AblyChatInstance } from '@/app/types/models';

export default {
  start(chat_options: any, saveSessionInfo: Function): AblyChatInstance {
    return window.live_chat(document.body, chat_options, saveSessionInfo);
  },
  init() {
    if (typeof window !== 'undefined') {
      if (!window.live_chat) {
        window.live_chat = function (selector, options, saveSessionInfo) {
          let ably = null;
          let activeChannel = null;
          let channels = null;
          let hasFocus = document.hasFocus();
          let client = null;
          let bot = null;

          const dom = {
            chat: null,
            module: null,
            title: null,
            container: null,
            channels: null,
            discussion: null,
            input: null,
            send: null,
            unread: null,
          };

          const template = {
            discussion: null,
            info: null,
            infoItem: null,
            options: null,
            optionsItem: null,
            channel: null,
          };

          options.title = options.title || 'Live Chat';
          options.subtitle = options.subtitle || 'Your dialogs';
          options.className = options.className || 'chat';

          const uuidv4 = () =>
            ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
              (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
            );

          const getUnreadCount = (channel) =>
            channel.received.length +
            (!channel.received.length && channel.isRestored && !channel.greetingSent ? 1 : 0);

          const checkUnread = () => {
            const channel = activeChannel;
            if (hasFocus && channel && dom.chat.classList.contains('sel')) {
              if (getUnreadCount(channel)) {
                // mark all received as delivered and seen
                channel.received.forEach((status) => {
                  status.delivered = true;
                  status.seen = true;
                });

                // publish all received for non-bot channel
                if (channel !== channels.bot) {
                  channel.instance.publish('status', channel.received);
                }

                // clear all received
                channel.received = [];
                channel.menu_item.classList.remove('unread');
                channel.menu_item.querySelector('.unread').innerText = 0;
              }

              // focus on text input
              dom.input.focus();
            }

            const count = channels.public
              .filter((c) => c.active)
              .reduce((a, c) => a + getUnreadCount(c), 0);
            dom.chat.classList.toggle('unread', count !== 0);
            dom.unread.innerText = count;
          };

          const focus = () => {
            hasFocus = true;
            checkUnread();
          };

          const blur = () => {
            hasFocus = false;
          };

          // prepare info message content
          const prepareInfo = (clientId, info) => {
            const domCont = template.info.cloneNode(true);
            domCont.classList.add(`info_${clientId}`);

            const domInfo = domCont.querySelector('.info_list');

            info.items.forEach((i) => {
              const domItem = template.infoItem.cloneNode(true);
              domItem.querySelector('.key').innerText = i.title;

              const domValue = domItem.querySelector('.value');
              if (i.link && i.link.length) {
                const domLink = document.createElement('a');
                domLink.target = '_blank';
                domLink.href = i.link;
                domLink.innerText = i.text;

                domValue.append(domLink);
              } else {
                domValue.innerText = i.text;
              }

              domInfo.append(domItem);
            });

            return domCont;
          };

          // prepend info message to the top of the channel container
          const prependInfo = (channel, clientId, info) => {
            // check if current client has access to read info messages of the channel
            if (
              options.infoGroups &&
              options.infoGroups.some((g) => g === info.infoGroup) &&
              channel.info_received.every((r) => r !== info.alias)
            ) {
              const domCont = prepareInfo(clientId, info);
              channel.discussion.prepend(domCont);
              channel.info_received.push(info.alias);
              channel.info = channel.info || info;

              return true;
            }

            return false;
          };

          // prepare choices content
          const prepareOptions = (options) => {
            const domCont = template.options.cloneNode(true);
            const domInfo = domCont.querySelector('.options_list');

            options.forEach((o) => {
              const domItem = template.optionsItem.cloneNode(true);

              const domChoice = domItem.querySelector('.choice');
              domChoice.innerText = o.text;
              domChoice.addEventListener('click', () => processBot(o));

              domInfo.append(domItem);
            });

            return domCont;
          };

          // append choices to the bot channel container
          const appendOptions = (channel, options) => {
            const domCont = prepareOptions(options);
            channel.discussion.append(domCont);

            if (channel === activeChannel) {
              scrollBottom();
            }
          };

          // prepend choices to the bot channel container
          const prependOptions = (channel, options) => {
            const domCont = prepareOptions(options);
            channel.discussion.prepend(domCont);
          };

          // prepare message content
          const prepareMessage = (channel, clientId, timestamp, message) => {
            const domCont = template.discussion.cloneNode(true);
            domCont.id = message.id;
            domCont.classList.add(
              `member_${clientId}`,
              clientId === options.clientId ? 'self' : 'other',
            );

            function formatAMPM(date) {
              let hours = date.getHours();
              let minutes = date.getMinutes();
              const ampm = hours >= 12 ? 'PM' : 'AM';
              hours = hours % 12;
              hours = hours || 12;
              minutes = minutes < 10 ? '0' + minutes : minutes;
              return hours + ':' + minutes + ' ' + ampm;
            }

            const member = channel.members.find((m) => m.id === clientId);
            if (member) {
              domCont.querySelector('.name').innerText = member.name;

              const domAvatar = domCont.querySelector('.avatar');
              domAvatar.title = member.name;
              domAvatar.style.backgroundImage = `url(${member.avatar})`;
              domAvatar.classList.toggle('active', member.active);
            }

            const date = new Date(timestamp);
            const domTime = domCont.querySelector('time.time');
            domTime.time = date.toISOString();
            domTime.innerText = formatAMPM(date);

            domCont.querySelector('.message').innerText = message.text;

            return domCont;
          };

          const getInfoValue = (info, title) => {
            let filtered = info.filter(x => x.title === title);
            const item = filtered && filtered.length ? filtered[0].text : null;
            return item ? parseInt(item) : null;
          }

          //save ably session info
          const saveAblySessionInfo = (channel) => {
            if (channel.isLastMessageSent
              || !channel.info
              || !channel.info.items
              || !channel.info.items.length
              || !saveSessionInfo
              || typeof saveSessionInfo !== 'function')
              return;

            const providerId = getInfoValue(channel.info.items, 'ProviderId')
            const leadContactId = getInfoValue(channel.info.items, 'LeadContactId')
            const customerChatChannelId = getInfoValue(channel.info.items, 'CustomerChatChannelId')

            if (providerId && leadContactId && customerChatChannelId){
              saveSessionInfo(providerId, leadContactId, customerChatChannelId);
            }
          }

          // append message to the bottom of the channel container
          const appendMessage = (channel, clientId, timestamp, message) => {
            // display message if it's not empty
            if (message.text && message.text.trim() !== '') {
              const domCont = prepareMessage(channel, clientId, timestamp, message);
              channel.discussion.append(domCont);

              channel.menu_item.querySelector('.message').innerText =
                (clientId === options.clientId ? 'you: ' : '') + message.text;

              if (channel === activeChannel) {
                scrollBottom();
              }
            }

            if (channel !== channels.bot) {
              if (clientId === options.clientId) {
                channel.greetingSent = true; // mark that we sent at least one message

                channel.sent.push({
                  id: message.id,
                  clientId: clientId,
                  delivered: false,
                  seen: false,
                });

                saveAblySessionInfo(channel)
                channel.isLastMessageSent = true;
              } else {
                const status = {
                  id: message.id,
                  clientId: clientId,
                  delivered: true,
                  seen: hasFocus && channel == activeChannel && dom.chat.classList.contains('sel'),
                };

                channel.instance.publish('status', [status]);
                if (!status.seen) {
                  channel.received.push(status);
                }

                channel.isLastMessageSent = false;
              }

              // set channel unread
              const count = getUnreadCount(channel);
              channel.menu_item.classList.toggle('unread', count !== 0);
              channel.menu_item.querySelector('.unread').innerText = count;

              // set global unread
              checkUnread();
            }
          };

          // prepend message to the top of the channel container
          const prependMessage = (channel, clientId, timestamp, message) => {
            // display message if it's not empty
            if (message.text && message.text.trim() !== '') {
              const domCont = prepareMessage(channel, clientId, timestamp, message);

              // prepend message, but after the last info message
              const domInfoList = channel.discussion.querySelectorAll('.info');
              if (domInfoList.length) {
                const domInfo = domInfoList[domInfoList.length - 1];
                domInfo.after(domCont);
              } else {
                channel.discussion.prepend(domCont);
              }

              if (clientId === options.clientId) {
                const status = channel.sent.find((s) => s.id === message.id);
                if (status) {
                  if (status.seen) {
                    domCont.querySelector('.status').classList.add('delivered', 'seen');
                  } else if (status.delivered) {
                    domCont.querySelector('.status').classList.add('delivered');
                  }
                }
              }
            }

            if (clientId === options.clientId) {
              channel.greetingSent = true; // mark that we sent at least one message
            } else {
              const status = channel.received.find((s) => s.id === message.id);
              if (!status) {
                channel.received.push({
                  id: message.id,
                  clientId: clientId,
                  delivered: false,
                  seen: false,
                });
              }
            }
          };

          // scroll to the top of channel/contacts container
          const scrollTop = () => {
            if (dom.container.children.length) {
              const section = dom.container.children[0];
              section.scrollTop = 0;
            }
          };

          // scroll to the bottom of channel/contacts container
          const scrollBottom = () => {
            if (dom.container.children.length) {
              const section = dom.container.children[0];
              section.scrollTop = section.scrollHeight;
            }
          };

          // creates channel content and append it to the chat container
          const addChannel = (channel) => {
            // add channel to the chat window
            const id = `channel-${channel.name}`.replace(':', '_');
            let domCont = dom.channels.querySelector(`#${id}`);
            if (!domCont) {
              domCont = template.channel.cloneNode(true);
              domCont.id = id;
              channel.menu_item = domCont;

              domCont.querySelector('.name').innerText = channel.title;

              const domAvatar = domCont.querySelector('.avatar');
              domAvatar.title = channel.title;
              domAvatar.style.backgroundImage = `url(${channel.icon})`;

              domCont.addEventListener('click', () => swapChannel(channel));

              dom.channels.append(domCont);

              channel.discussion = dom.discussion.cloneNode(true);
            }
          };

          // initialize new chat channel (subscribe, read history and etc.)
          const initChannel = (channel) => {
            function switchFromBot() {
              if (channels.bot) {
                Array.prototype.slice.call(channel.discussion.children).forEach((x) => {
                  if (x.classList.contains('from-bot')) {
                    x.remove();
                  }
                });

                Array.prototype.slice
                  .call(channels.bot.discussion.children)
                  .reverse()
                  .forEach((n) => {
                    n.classList.add('from-bot');
                    channel.discussion.prepend(n);
                  });
              }

              toggleSingle();
            }

            // init channel
            channel.active = true;

            // if channel already exists - switch to it, otherwise create and initialize
            if (channel.discussion) {
              switchFromBot();
            } else {
              addChannel(channel);

              // init channel
              channel.instance = ably.channels.get(channel.name);

              // get all members from the history
              channel.instance.presence.history((error, d) => {
                function publishMember(d, active) {
                  let member = channel.members.find((m) => m.id === d.clientId);
                  if (!member) {
                    member = {
                      id: d.clientId,
                      name: d.data.name,
                      avatar: d.data.avatar,
                      active: false,
                      alert_channel: null,
                    };

                    channel.members.push(member);

                    // add member title and avatar if messages already attached
                    channel.discussion.querySelectorAll(`.member_${d.clientId}`).forEach((x) => {
                      x.querySelector('.name').innerText = member.name;

                      const domAvatar = x.querySelector('.avatar');
                      domAvatar.title = member.name;
                      domAvatar.style.backgroundImage = `url(${member.avatar})`;
                    });
                  }

                  if (active) {
                    member.active = true;
                    channel.discussion
                      .querySelectorAll(`.member_${d.clientId} .avatar`)
                      .forEach((x) => x.classList.add('active'));
                  }
                }

                d.items.forEach((x) => publishMember(x, false));

                // get all members - check state of predefined members
                channel.instance.presence.get((error, d) =>
                  d.forEach((x) => publishMember(x, true)),
                );

                // enter and start
                channel.instance.presence.enter({
                  name: client.name,
                  avatar: client.avatar,
                });

                // receive entered members
                channel.instance.presence.subscribe('enter', (d) => publishMember(d, true));

                // receive members who left
                channel.instance.presence.subscribe('leave', (d) => {
                  if (d.clientId !== options.clientId) {
                    const member = channel.members.find((m) => m.id === d.clientId);
                    if (member) {
                      member.active = false;
                      channel.discussion
                        .querySelectorAll(`.member_${d.clientId} .avatar`)
                        .forEach((x) => x.classList.remove('active'));
                    }
                  }
                });

                // get old messages from the history
                channel.instance.history((error, d) => {
                  let isLast = true;
                  d.items.forEach((x) => {
                    switch (x.name) {
                      case 'message': {
                        Array.prototype.slice.call(channel.discussion.children).forEach((x) => {
                          if (x.classList.contains('from-bot')) {
                            x.remove();
                          }
                        });

                        prependMessage(channel, x.clientId, x.timestamp, x.data);

                        // write message text to the channel item
                        if (isLast) {
                          isLast = false;
                          channel.menu_item.querySelector('.message').innerText =
                            (x.clientId === options.clientId ? 'you: ' : '') + x.data.text;
                        }

                        break;
                      }

                      case 'info': {
                        Array.prototype.slice.call(channel.discussion.children).forEach((x) => {
                          if (x.classList.contains('from-bot')) {
                            x.remove();
                          }
                        });

                        prependInfo(channel, x.clientId, x.data);
                        break;
                      }

                      case 'status':
                        if (x.clientId !== options.clientId) {
                          x.data.forEach((s) => {
                            if (s.clientId === options.clientId) {
                              const r = channel.sent.find((y) => y.id === s.id);
                              if (!r) {
                                channel.sent.push(s);
                              }
                            }
                          });
                        } else {
                          x.data.forEach((s) => {
                            if (s.clientId !== options.clientId) {
                              const r = channel.received.find((y) => y.id === s.id);
                              if (!r) {
                                channel.received.push(s);
                              }
                            }
                          });
                        }
                        break;

                      default:
                        break;
                    }
                  });

                  channel.sent = []; // clean sent after history
                  channel.received = channel.received.filter((s) => !s.seen); // remove seen messages

                  // send delivered status
                  const undelivered = channel.received.filter((s) => {
                    if (!s.delivered) {
                      s.delivered = true;
                      return true;
                    }

                    return false;
                  });

                  if (undelivered.length) {
                    channel.instance.publish('status', undelivered);
                  }

                  // check channel unread
                  const count = getUnreadCount(channel);
                  channel.menu_item.classList.toggle('unread', count !== 0);
                  channel.menu_item.querySelector('.unread').innerText = count;

                  // check global unread
                  checkUnread();

                  // scroll to bottom if it's active channel
                  if (channel === activeChannel) {
                    scrollBottom();
                  }

                  // receive message status
                  channel.instance.subscribe('info', (d) =>
                    prependInfo(channel, d.clientId, d.data),
                  );

                  // subscibe message
                  channel.instance.subscribe('message', (d) =>
                    appendMessage(channel, d.clientId, d.timestamp, d.data),
                  );

                  // receive message status
                  channel.instance.subscribe('status', (d) => {
                    d.data.forEach((s) => {
                      if (s.clientId === options.clientId) {
                        if (s.seen) {
                          channel.discussion
                            .querySelector(`#${s.id} .status`)
                            .classList.add('delivered', 'seen');
                          channel.sent = channel.sent.filter((x) => x.id !== s.id);
                        } else if (s.delivered) {
                          channel.discussion
                            .querySelector(`#${s.id} .status`)
                            .classList.add('delivered');
                        }
                      }
                    });
                  });

                  // send info message if exists
                  if (channel.info) {
                    prependInfo(channel, options.clientId, channel.info);
                    channel.instance.publish('info', channel.info);

                    sendAlert(channel);
                  }

                  channel.historyFetched = true;
                  switchFromBot();
                });
              });
            }
          };

          // process next bot action
          const processBot = (action) => {
            function processMessage() {
              // this is message
              const clientId = bot.id;
              const message = {
                id: 'msg-' + action.id,
                text: action.text,
              };

              c.received.push({
                id: message.id,
                clientId: clientId,
                delivered: true,
                seen: true,
              });

              appendMessage(c, clientId, Date.now(), message);
              processBot(action.next);
            }

            const humanTime = 1000;
            const c = channels.bot;

            // make sure we loaded the history
            if (!c.historyFetched) {
              return;
            }

            if (!action) {
              if (!c.lastAction || c.lastAction.type == 'channel') {
                action = c.action;
              } else if (c.lastAction && c.lastAction.type !== 'options') {
                action = c.lastAction.next;
              } else {
                return; // exit as we wait for user choose the option
              }
            }

            c.lastAction = action;

            // publish action into the bot channel
            c.instance.publish(action.type, action.id);

            switch (action.type) {
              case 'message': {
                // if message is not the first element of the tree we set little timeout to simulate human
                if (action === c.action) {
                  processMessage();
                } else {
                  setTimeout(processMessage, humanTime);
                }

                break;
              }

              case 'choice': {
                // this is the choice
                const clientId = options.clientId;
                const message = {
                  id: 'msg-' + action.id,
                  text: action.text,
                };

                c.sent.push({
                  id: message.id,
                  clientId: clientId,
                  delivered: true,
                  seen: true,
                });

                const domOptionsList = c.discussion.querySelectorAll('.options');
                if (domOptionsList.length) {
                  domOptionsList[domOptionsList.length - 1].remove();
                }

                appendMessage(c, clientId, Date.now(), message);

                processBot(action.next);
                break;
              }

              case 'options': {
                // if message is not the first element of the tree we set little timeout to simulate human
                if (action === c.action) {
                  appendOptions(c, action.next);
                } else {
                  setTimeout(() => appendOptions(c, action.next), humanTime);
                }

                break;
              }

              case 'channel': {
                setTimeout(() => {
                  c.active = false;
                  initChannel(channels.public.find((f) => f.name === action.channelName));
                }, humanTime);

                break;
              }

              default:
                break;
            }
          };

          // swap containers between channel<->channel and channel<->contacts
          const swapContainer = () => {
            Array.prototype.slice.call(dom.container.children).forEach((c) => c.remove());

            dom.module.classList.toggle('sel', activeChannel !== null);
            dom.module.classList.toggle('bot', channels && activeChannel === channels.bot);

            if (activeChannel) {
              dom.container.append(activeChannel.discussion);
              scrollBottom();
              dom.title.innerText = activeChannel.title;

              if (activeChannel === channels.bot) {
                processBot();
              } else {
                // send greeting message if the history is empty
                if (
                  activeChannel.isRestored &&
                  !activeChannel.greetingSent &&
                  hasFocus &&
                  dom.chat.classList.contains('sel')
                ) {
                  const message = {
                    id: 'msg-' + uuidv4(),
                    text: options.greeting && options.greeting.length ? options.greeting : null,
                  };

                  appendMessage(activeChannel, options.clientId, Date.now(), message);
                  activeChannel.instance.publish('message', message);
                }

                // focus on text input
                dom.input.focus();
              }

              // check global unread count
              checkUnread();
            } else {
              dom.container.append(dom.channels);
              scrollTop();
              dom.title.innerText = options.subtitle;
            }
          };

          // swap containers to the active channel
          const swapChannel = (channel) => {
            activeChannel = channel;
            swapContainer();
          };

          // toogle class and try to swap container to active channel if there are no others
          const toggleSingle = () => {
            // check if only one chat channel
            const activePublic = channels.public.filter((c) => c.active);
            const single =
              (channels.bot && channels.bot.active) || (!channels.bot && activePublic.length === 1);
            dom.module.classList.toggle('single', single);

            if (channels.bot && channels.bot.active) {
              swapChannel(channels.bot);
            } else if (activePublic.length === 1) {
              swapChannel(activePublic[0]);
            } else {
              swapChannel(null);
            }
          };

          // initialize all chat DOM elements, containers, content and templates
          const initChat = () => {
            const chatHtml = `
				<div>
					<div>
						<section class="module">
							<header>
								<div>
									<span class="size"></span>
									<div>
										<button class="home" title="Back"><i></i></button>
										<div>
											<p class="title"></p>
											<p class="subtitle"></p>
										</div>
										<button class="close"></button>
									</div>
								</div>
							</header>
							<main>
								<div class="container"></div>
							</main>
							<footer>
								<div>
									<div>
										<input class="text" placeholder="Write a message..." />
										<button class="send" title="Send message" />
									</div>
								</div>
							</footer>
						</section>
						<button class="button" title="Toggle Chat"><i>0</i></button>
					</div>
					<div></div>
				</div>`;

            const discussionHtml = `
				<ul class="discussion">
					<li class="info">
						<ul class="info_list">
							<li><span class="key"></span>: <span class="value"></span></li>
						</ul>
					</li>
					<li class="options">
						<ul class="options_list">
							<li><button class="choice"></button></li>
						</ul>
					</li>
					<li class="msg">
						<div class="avatar"></div>
						<div>
							<p class="name"></p>
							<p class="message"></p>
							<div>
								<span class="status"></span>
								<time class="time"></time>
							</div>
						</div>
					</li>
				</ul>`;

            const channelsHtml = `
				<ul class="channels">
					<li class="item">
						<div><div class="avatar channel_status"></div></div>
						<div>
							<div class="name"></div>
							<p class="message">&nbsp;</p>
						</div>
						<div><i class="unread">0</i></div>
					</li>
				</ul>`;

            function createElementFromHTML(htmlString) {
              var div = document.createElement('div');
              div.innerHTML = htmlString.trim();
              return div.children[0];
            }

            dom.chat = createElementFromHTML(chatHtml);
            dom.chat.classList.add(options.className);

            dom.container = dom.chat.querySelector('.container');

            dom.discussion = createElementFromHTML(discussionHtml);
            dom.channels = createElementFromHTML(channelsHtml);

            template.discussion = Array.prototype.slice
              .call(dom.discussion.children)
              .find((x) => x.classList.contains('msg'));
            template.discussion.remove();

            template.info = dom.discussion.querySelector('.info');
            template.info.remove();

            template.infoItem = template.info.querySelector('.info_list > *');
            template.infoItem.remove();

            template.options = Array.prototype.slice
              .call(dom.discussion.children)
              .find((x) => x.classList.contains('options'));
            template.options.remove();

            template.optionsItem = template.options.querySelector('.options_list > *');
            template.optionsItem.remove();

            template.channel = Array.prototype.slice
              .call(dom.channels.children)
              .find((x) => x.classList.contains('item'));
            template.channel.remove();

            dom.module = dom.chat.querySelector('.module');
            dom.input = dom.chat.querySelector('input.text');
            dom.send = dom.chat.querySelector('.send');
            dom.unread = dom.chat.querySelector('.button > *');

            dom.chat.querySelector('.title').innerText = options.title;

            dom.title = dom.chat.querySelector('.subtitle');
            dom.title.innerText = options.subtitle;

            if (selector instanceof Element) selector.append(dom.chat);
            else if (selector && selector.length) document.querySelector(selector).append(dom.chat);
            else document.body.append(dom.chat);

            // preload avatar
            document.createElement('img').src = client.avatar;

            swapContainer();
          };

          // init main ably instance with all predefined channels and etc.
          const initAbly = () => {
            function initBotChannel(c) {
              function findAction(action, id) {
                if (action.id === id) {
                  return action;
                }

                if (action.type === 'options') {
                  const length = action.next.length;
                  for (let i = 0; i < length; ++i) {
                    const found = findAction(action.next[i], id);
                    if (found) {
                      return found;
                    }
                  }
                } else if (action.type !== 'channel') {
                  return findAction(action.next, id);
                }

                return null;
              }

              // recursive function to reorginize and assign ID on each action from the tree
              function indexActions(obj, action, id) {
                if (obj === c) {
                  obj.action = {
                    id: `${id++}`,
                  };
                  obj = obj.action;
                } else {
                  obj.next = {
                    id: `${id++}`,
                  };
                  obj = obj.next;
                }

                if ({}.toString.call(action) === '[object Array]') {
                  obj.type = 'options';
                  obj.next = action.map((a) => {
                    const item = {
                      id: `${id++}`,
                      type: 'choice',
                      text: a.text,
                    };

                    id = indexActions(item, a.next, id);
                    return item;
                  });
                } else if (action.next) {
                  obj.type = 'message';
                  obj.text = action.text;
                  id = indexActions(obj, action.next, id);
                } else {
                  obj.type = 'channel';
                  obj.channelName = action.channelName;
                }

                return id;
              }

              indexActions(c, options.bot.action, 0);
              addChannel(c);

              // init channel
              c.instance = ably.channels.get(c.name);

              // check the bot channel history and determine if last message is a channel message
              c.instance.history((error, d) => {
                let isLast = true;
                d.items.every((x) => {
                  const action = findAction(c.action, x.data);
                  if (isLast) {
                    c.lastAction = action;
                  }

                  switch (x.name) {
                    case 'message': {
                      const clientId = bot.id;

                      if (isLast) {
                        c.lastAction = action;
                      }

                      const message = {
                        id: 'msg-' + action.id,
                        text: action.text,
                      };

                      c.received.push({
                        id: message.id,
                        clientId: clientId,
                        delivered: true,
                        seen: true,
                      });

                      prependMessage(c, clientId, x.timestamp, message);
                      break;
                    }

                    case 'options': {
                      // check if this message is the last and if yes - render choices
                      if (isLast) {
                        prependOptions(c, action.next);
                      }

                      break;
                    }

                    case 'choice': {
                      const clientId = options.clientId;
                      const message = {
                        id: 'msg-' + action.id,
                        text: action.text,
                      };

                      c.sent.push({
                        id: message.id,
                        clientId: clientId,
                        delivered: true,
                        seen: true,
                      });

                      prependMessage(c, clientId, x.timestamp, message);

                      break;
                    }

                    case 'channel': {
                      // check if this message is the last and redirect to proper channel
                      if (isLast) {
                        c.active = false;
                        initChannel(channels.public.find((f) => f.name === action.channelName));
                      }

                      return false; // exit loop if it's channel
                    }

                    default:
                      break;
                  }

                  isLast = false;
                  return action !== c.action; // break if it's first action of the tree
                });

                c.historyFetched = true;
                toggleSingle();
              });
            }

            function restoreChannel(d) {
              if (
                d.clientId !== options.clientId &&
                channels.public.every((x) => x.name !== d.data.name)
              ) {
                const c = {
                  active: false,
                  name: d.data.name,
                  title: d.data.title,
                  icon: d.data.icon,
                  members: [],
                  info: null,
                  info_received: [],
                  messages: [],
                  sent: [],
                  greetingSent: false,
                  isLastMessageSent: false,
                  historyFetched: false,
                  isRestored: true,
                  received: [],
                  instance: null,
                };

                channels.public.push(c);
                return c;
              }

              return null;
            }

            // init Ably Realtime
            ably = new Ably.Realtime({
              key: options.apiKey,
              token: options.token,
              clientId: options.clientId,
              echoMessages: false,
            });

            // init channels
            channels = {
              bot: options.bot
                ? {
                    active: true,
                    name: `bot:${options.bot.alias}`, // hardcoded 'bot' namespace
                    title: options.bot.name,
                    icon: options.bot.avatar,
                    members: [client, bot],
                    info: null,
                    info_received: [],
                    messages: [],
                    sent: [],
                    greetingSent: false,
                    isLastMessageSent: false,
                    historyFetched: false,
                    isRestored: false,
                    received: [],
                    instance: null,
                  }
                : null,
              public: options.channels.map(function (c) {
                // covered with function keyword due to bug in JS minifier
                return {
                  active: false,
                  name: c.name,
                  title: c.title,
                  icon: c.icon,
                  members: c.memberIds.map((id) => {
                    const m = options.members.find((x) => x.id === id);
                    return {
                      id: m.id,
                      name: m.name,
                      avatar: m.avatar,
                      active: false,
                      alert_channel: null,
                    };
                  }),
                  info: c.info,
                  info_received: [],
                  messages: [],
                  sent: [],
                  greetingSent: false,
                  isLastMessageSent: false,
                  historyFetched: false,
                  isRestored: false,
                  received: [],
                  instance: null,
                };
              }),
              alert: null,
            };

            function activatePublicChannels() {
              // get alert channel
              channels.alert = ably.channels.get(`alerts:${options.clientId}`);

              // get old alerts from the history
              channels.alert.history((error, d) => {
                d.items.forEach(restoreChannel);

                // subscribe to public channels
                channels.public.forEach(initChannel);

                // subscribe to alerts channel
                channels.alert.subscribe('alerts', (d) => {
                  const c = restoreChannel(d);
                  if (c) {
                    initChannel(c);
                  }
                });

                if (!activeChannel) {
                  scrollTop();
                }
              });
            }

            // check if bot channel is configured
            if (channels.bot) {
              initBotChannel(channels.bot);
            } else {
              activatePublicChannels();
            }
          };

          // send message to the alert channel
          const sendAlert = (channel) => {
            // send alerts for non-active members
            channel.members.forEach((m) => {
              if (!m.active) {
                if (!m.alert_channel) {
                  m.alert_channel = ably.channels.get(`alerts:${m.id}`);
                }

                m.alert_channel.publish('alerts', {
                  name: channel.name,
                  title: `${channel.title} - ${client.name}`,
                  icon: client.avatar,
                });
              }
            });
          };

          // attach events to the main chat controls
          const initEvents = () => {
            function initToogle() {
              // toggle chat
              dom.chat.querySelectorAll('.button, .close').forEach((e) => {
                e.addEventListener('click', () => {
                  dom.chat.classList.toggle('sel');
                  swapContainer();
                  checkUnread();
                });
              });

              dom.module.querySelector('.home').addEventListener('click', () => {
                if (channels.bot && !channels.bot.active) {
                  channels.bot.active = true;
                  channels.public.forEach((p) => {
                    p.active = false;
                  });
                  toggleSingle();
                } else {
                  swapChannel(null);
                }
              });
            }

            function initSend() {
              // send message
              dom.send.addEventListener('click', () => {
                const channel = activeChannel;
                if (channel) {
                  const text = dom.input.value;
                  if (text.length) {
                    const message = {
                      id: 'msg-' + uuidv4(),
                      text: text,
                    };

                    // send message
                    appendMessage(channel, options.clientId, Date.now(), message);
                    channel.instance.publish('message', message);

                    sendAlert(channel);

                    dom.input.value = '';
                  }
                }
              });

              // send message on input key down
              dom.input.addEventListener('keypress', (e) => {
                if (e.keyCode === 13) {
                  dom.send.click();
                }
              });
            }

            function initResize() {
              function resize(e) {
                dom.module.style.width = size.width - e.clientX + 'px';
                dom.module.style.height = size.height - e.clientY + 'px';

                scrollBottom();
              }

              // resize events
              let size = null;
              dom.chat.querySelector('.size').addEventListener('mousedown', (e) => {
                if (!size && !e.button) {
                  var moduleStyle = getComputedStyle(dom.module, null);

                  size = {
                    width: e.clientX + parseFloat(moduleStyle.width.replace('px', '')),
                    height: e.clientY + parseFloat(moduleStyle.height.replace('px', '')),
                  };

                  dom.chat.classList.add('resize');
                }
              });

              document.addEventListener('mousemove', (e) => {
                if (size) {
                  resize(e);
                }
              });

              document.addEventListener('mouseup', (e) => {
                if (size && !e.button) {
                  resize(e);

                  dom.chat.classList.remove('resize');
                  size = null;
                }
              });
            }

            initToogle();
            initSend();
            initResize();

            window.addEventListener('focus', focus);
            window.addEventListener('blur', blur);
          };

          // prepare current client
          client = options.members.find((m) => m.id === options.clientId);
          client.active = true;
          client.alert_channel = null;

          // prepare bot if exists and push into main members list
          if (options.bot) {
            bot = {
              id: `bot-${uuidv4()}`,
              name: options.bot.name,
              avatar: options.bot.avatar,
              active: true,
              alert_channel: null,
            };
            options.members.push(bot);
          }

          // start initializing
          initChat();
          initAbly();
          initEvents();

          return {
            destroy: () => {
              window.removeEventListener('focus', focus);
              window.removeEventListener('blur', blur);

              channels.public.forEach((c) => {
                if (c.instance) {
                  c.instance.presence.leave();
                  c.instance.detach();
                }
              });

              if (channels.alert && channels.alert.instance) {
                channels.alert.instance.detach();
              }

              ably.close();

              dom.chat.remove();
            },
          };
        };
      }
    }
  },
};
